import React from "react";
import { CommonHeader } from "header-component";
import "./index.css"
const CustomHeader = () => {
  return (
    <div>
     <CommonHeader />
    </div>
    
  );
};
export default CustomHeader;
